import { ActionTypes } from '../constants'
import api from '../utils/api'

export const getLocationsByDate = (momentTimestamp) => async (dispatch) => {
  const result = await api.post('/protections/_for-map', {
    date: momentTimestamp,
  })
  dispatch({
    type: ActionTypes.INITIALIZE_LOCATIONS,
    data: result.data,
  })
}
