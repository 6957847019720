import React, { useState } from 'react'
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from '@material-ui/pickers'
// import DateFnsUtils from '@date-io/date-fns'
import Button from '@material-ui/core/Button'
import CachedIcon from '@material-ui/icons/Cached'
// import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import moment from 'moment-timezone'
import { useMediaPredicate } from 'react-media-hook'

import Logo from '../assets/images/logo_retina.png'
import FilterTableEntry from './filterTableEntry'
// import { colors } from '../utils/constants'

export default ({
  propertiesData,
  excludedJobsId,
  // dateFetched,
  onJobsFilter,
  onReset,
  // onDateChange,
  style,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const isMobile = useMediaPredicate('(max-width: 768px)')

  const collapseIconsStyling = {
    color: 'gray',
    marginTop: '2px',
  }
  return (
    <div
      style={{
        backgroundColor: isMobile ? 'white' : 'rgba(250, 250, 250, 0.85)',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        right: isMobile ? 'unset' : '5vw',
        top: isMobile ? 'unset' : '10vh',
        bottom: isMobile ? '-55%' : 'unset',
        width: isMobile ? '100%' : '18.5vw',
        borderRadius: '5px',
        ...style,
      }}>
      <div
        style={{
          padding: '20px 23px 7px 23px',
          backgroundColor: 'white',
          opacity: 0.85,
          textAlign: 'center',
        }}>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={dateFetched}
            onChange={(newFetchedDate) =>
              onDateChange(moment(newFetchedDate).startOf('day').format())
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            style={{
              width: '100%',
              color: colors.textGrey,
              fontFamily: "'Roboto Light'",
              fontSize: '15px',
              fontWeight: 100,
            }}
          />
        </MuiPickersUtilsProvider> */}
        <button
          style={{
            backgroundColor: 'white',
            textAlign: 'center',
            border: 0,
            paddingBottom: '12px',
            outline: 'none',
          }}
          onClick={() => !isMobile && setIsCollapsed(!isCollapsed)}>
          <img src={Logo} alt="logo" style={{ width: '69%' }} />
          {isMobile ? null : isCollapsed ? (
            <ExpandMoreIcon style={collapseIconsStyling} />
          ) : (
            <ExpandLessIcon style={collapseIconsStyling} />
          )}
        </button>
      </div>
      <div
        style={{
          display: isCollapsed ? 'none' : 'flex',
          flexDirection: 'column',
          right: '5vw',
          top: '10vh',
          width: isMobile ? '100%' : '18.5vw',
          height: '60vh',
          borderRadius: '5px',
        }}>
        <div style={{ overflowY: 'scroll' }}>
          <div
            style={{ padding: isMobile ? '7px 23px' : '20px 23px 7px 23px' }}>
            <span
              style={{
                flex: 2,
                color: '#2C3B8B',
                fontFamily: 'Roboto',
                fontSize: isMobile ? 'unset' : '1.2vw',
                letterSpacing: '0',
                lineHeight: '25px',
              }}>
              Select Property:
            </span>
          </div>
          <FilterTableEntry
            isViewAll
            value={propertiesData.reduce(
              (accumulatedActiveJobs, { activeJobs }) =>
                accumulatedActiveJobs + activeJobs,
              0
            )}
            excludedJobsId={excludedJobsId}
            onJobsFilter={onJobsFilter}
          />
          {propertiesData.map(
            ({ id, color, name, activeJobs, usersData }, propertyIndex) => (
              <FilterTableEntry
                id={id}
                color={color}
                title={name}
                value={activeJobs}
                key={`${name}-${activeJobs}-${propertyIndex}`}
                excludedJobsId={excludedJobsId}
                onJobsFilter={onJobsFilter}
                usersData={usersData}
              />
            )
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '15px 10px',
            backgroundColor: 'white',
            borderRadius: '0px 0px 5px 5px',
            minHeight: '65px',
          }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: '100%',
              padding: '0px',
            }}
            onClick={onReset}>
            <CachedIcon
              style={{
                fontSize: isMobile ? 'unset' : '1.5vw',
                position: 'absolute',
                left: '20px',
              }}
            />
            <span
              style={{
                margin: '6px 0px',
                color: '#FFFFFF',
                fontFamily: 'Roboto',
                fontSize: isMobile ? 'unset' : '0.8vw',
                fontWeight: 500,
                letterSpacing: '1.3px',
                lineHeight: '21px',
                textAlign: 'center',
              }}>
              RESET
            </span>
          </Button>
          {/* <Button
            variant="outlined"
            color="primary"
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flex: 5,
              width: '100%',
              padding: '0px',
            }}
            onClick={() => console.log('clicked')}>
            <CameraAltOutlinedIcon style={{ fontSize: '1.5vw' }} />
            <span
              style={{
                padding: '6px 0px',
                fontFamily: 'Roboto',
                fontSize: '0.8vw',
                fontWeight: 500,
                letterSpacing: '1.3px',
                lineHeight: '21px',
                textAlign: 'center',
              }}>
              SCREENSHOT
            </span>
          </Button> */}
        </div>
      </div>
    </div>
  )
}
