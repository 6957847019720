import React from 'react'
import Grid from '@material-ui/core/Grid'

import { LoginOutButton } from '../../components'

const SignIn = ({ setMsalInstance }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="center"
    style={{ height: '100vh' }}>
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        height: '250px',
        width: '450px',
        boxShadow: 'rgba(35, 59, 145, 0.15) 0px 22px 26px 0px',
      }}>
      <Grid item xs={12}>
        <img
          alt="logo"
          src="https://railpros-otss-job-briefings.s3.us-east-2.amazonaws.com/settings/logos/logo-title.png"
          style={{ maxWidth: '100%' }}
        />
      </Grid>
      <Grid item container alignItems="center" justifyContent="center" xs={12}>
        <LoginOutButton setMsalInstance={setMsalInstance} isLogin />
      </Grid>
    </Grid>
  </Grid>
)

export default SignIn
