import moment from 'moment-timezone'

export const getHasAlreadyReportedToDuty = (userData) => {
  let isOnDuty = false

  const lastReportedToDutyInCurrentTimezone =
    userData.last_reported_to_duty_at_utc &&
    userData.last_reported_to_duty_at_utc !== 'null' &&
    userData.last_reported_to_duty_timezone &&
    userData.last_reported_to_duty_timezone !== 'null'
      ? moment(userData.last_reported_to_duty_at_utc).tz(
          userData.last_reported_to_duty_timezone
        )
      : false

  const lastDebriefedInCurrentTimezone =
    userData.last_debriefed_at_utc &&
    userData.last_debriefed_at_utc !== 'null' &&
    userData.last_debriefed_timezone &&
    userData.last_debriefed_timezone !== 'null'
      ? moment(userData.last_debriefed_at_utc).tz(
          userData.last_debriefed_timezone
        )
      : false
  if (
    lastReportedToDutyInCurrentTimezone &&
    (!lastDebriefedInCurrentTimezone ||
      (lastDebriefedInCurrentTimezone &&
        lastReportedToDutyInCurrentTimezone.isAfter(
          lastDebriefedInCurrentTimezone
        )))
  ) {
    isOnDuty = true
  }
  return isOnDuty
}
