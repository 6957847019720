import React, { useState } from 'react'
import RoomIcon from '@material-ui/icons/Room'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useMediaPredicate } from 'react-media-hook'

import { colors } from '../utils/constants'
import { getHasAlreadyReportedToDuty } from '../utils/users'

export default ({
  id,
  color,
  title,
  value,
  onJobsFilter,
  excludedJobsId,
  isViewAll,
  usersData,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const isMobile = useMediaPredicate('(max-width: 768px)')

  const allJobIdsForProperty =
    !isViewAll &&
    Object.values(usersData)
      .map(({ jobIds }) => jobIds)
      .flat()

  const getIsVisible = (jobIds) =>
    jobIds.some((jobId) => !excludedJobsId.includes(jobId))

  const dynamicTextStyle = (isVisible) => {
    return {
      color: isVisible ? '#2C3B8B' : '#676767',
      fontWeight: isVisible && 'bold',
      fontFamily: 'Roboto',
      fontSize: isMobile ? 'unset' : '1vw',
      letterSpacing: 0,
      lineHeight: isMobile ? 'unset' : '1.2vw',
    }
  }

  const toggleExpansion = (e) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  const expansionStyles = {
    color: 'grey',
  }
  const isHeaderVisible = isViewAll
    ? excludedJobsId.length === 0
    : getIsVisible(allJobIdsForProperty)

  return (
    <>
      <div
        style={{
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          padding: '0.6vw 1.2vw 0.6vw 0.6vw',
          cursor: 'pointer',
          backgroundColor: isHeaderVisible ? 'white' : 'inherit',
        }}
        onClick={() =>
          onJobsFilter({
            isViewAll,
            jobIds: !isViewAll && allJobIdsForProperty,
          })
        }>
        <RoomIcon style={{ color: color, flex: 2, opacity: !color ? 0 : 1 }} />
        <span style={{ flex: 8.5, ...dynamicTextStyle(isHeaderVisible) }}>
          {isViewAll ? 'View All' : title}
        </span>
        <span
          style={{
            flex: 1.5,
            textAlign: 'right',
            ...dynamicTextStyle(isHeaderVisible),
          }}>
          {value}
        </span>
        {isExpanded ? (
          <ExpandLessIcon style={expansionStyles} onClick={toggleExpansion} />
        ) : (
          !isViewAll && (
            <ExpandMoreIcon style={expansionStyles} onClick={toggleExpansion} />
          )
        )}
      </div>
      {isExpanded &&
        Object.keys(usersData)
          .sort()
          .map((userName, userNameIndex) => {
            const currentUserData = usersData[userName]
            const isOnDuty = getHasAlreadyReportedToDuty(currentUserData)
            const isUserVisible = getIsVisible(currentUserData.jobIds)
            return (
              <div
                key={`user-${userNameIndex}-${id}`}
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  padding: isMobile
                    ? '0.8vw 7.5vw 0.6vw 2.6vw'
                    : '0.8vw 2.6vw 0.6vw 2.6vw',
                  cursor: 'pointer',
                  backgroundColor: isUserVisible ? 'white' : 'inherit',
                }}
                onClick={() =>
                  onJobsFilter({ jobIds: currentUserData.jobIds })
                }>
                <span
                  style={{
                    flex: 7,
                    marginLeft: isMobile ? '13.4vw' : '0.6vw',
                    ...dynamicTextStyle(isUserVisible),
                  }}>
                  {userName}
                </span>
                {isOnDuty && (
                  <span
                    style={{
                      flex: 3.5,
                      marginLeft: '0.6vw',
                      ...dynamicTextStyle(isUserVisible),
                      borderRadius: 20,
                      backgroundColor: colors.redDark,
                      color: 'white',
                      fontSize: isMobile ? 'unset' : '0.7vw',
                      textAlign: 'center',
                    }}>
                    On Duty
                  </span>
                )}
                <span
                  style={{
                    flex: 1.5,
                    textAlign: 'right',
                    ...dynamicTextStyle(isUserVisible),
                  }}>
                  {currentUserData.jobIds.length}
                </span>
              </div>
            )
          })}
    </>
  )
}
