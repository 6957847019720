import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import FlagIcon from '@material-ui/icons/Flag'
import LockIcon from '@material-ui/icons/Lock'

import JobBriefingPin from './jobBriefingPin'
import FilterTable from './filterTable'
import LoginOutButton from './loginOutButton'

import { getAllPropertiesData } from '../actions/properties'
import { getPropertyColor } from '../utils/hofs'
import { getFlagType, getFlagIcon, buildFlagMarkerLabel } from '../utils/flags'
import { colors } from '../utils/constants'

export default ({
  jobBriefingData,
  dateFetched,
  handleOnDateChange,
  msalInstance,
}) => {
  const defaultCenter = { lat: 38.61073, lng: -96.4247 }
  const defaultZoom = 4.9
  const protectionZoom = 13

  const [propertiesData, setPropertiesData] = useState([])
  const [excludedJobsId, setExcludedJobsId] = useState([])
  const [toggledProtection, setToggledProtection] = useState(undefined)

  let mapReference = undefined

  useEffect(() => {
    initialize()
  }, [jobBriefingData])

  const matchPropertiesWithLocationData = (dataToMatch) =>
    dataToMatch.map((propertyData) => {
      const activeJobsForProperty = jobBriefingData.filter(
        ({ org_id }) => org_id === propertyData.id
      )
      return {
        ...propertyData,
        color: getPropertyColor(propertyData.name),
        activeJobs: activeJobsForProperty.length,
        usersData: activeJobsForProperty.reduce(
          (accumulatedData, currentData) => {
            if (accumulatedData[currentData.created_by_user_name]) {
              return {
                ...accumulatedData,
                [currentData.created_by_user_name]: {
                  jobIds: [
                    ...accumulatedData[currentData.created_by_user_name].jobIds,
                    currentData.id,
                  ],
                },
              }
            }
            return {
              ...accumulatedData,
              [currentData.created_by_user_name]: {
                jobIds: [currentData.id],
                last_reported_to_duty_at_utc:
                  currentData.last_reported_to_duty_at_utc,
                last_reported_to_duty_timezone:
                  currentData.last_reported_to_duty_timezone,
                last_debriefed_at_utc: currentData.last_debriefed_at_utc,
                last_debriefed_timezone: currentData.last_debriefed_timezone,
              },
            }
          },
          {}
        ),
      }
    })

  const initialize = () => {
    const allPropertiesData = getAllPropertiesData()
    const matchedPropertiesData = matchPropertiesWithLocationData(
      allPropertiesData
    )

    setPropertiesData(matchedPropertiesData)
  }

  const handleOnJobsFilter = ({ jobIds, isViewAll = false }) => {
    if (isViewAll) {
      if (excludedJobsId.length === 0) {
        setExcludedJobsId(jobBriefingData.map(({ id }) => id))
      } else {
        setExcludedJobsId([])
      }
    } else {
      const filteredJobsAreExcluded = jobIds.every((val) =>
        excludedJobsId.includes(val)
      )
      if (filteredJobsAreExcluded) {
        setExcludedJobsId(
          excludedJobsId.filter(
            (excludedOrgId) => !jobIds.includes(excludedOrgId)
          )
        )
      } else {
        setExcludedJobsId(excludedJobsId.concat(jobIds))
      }
    }
  }

  const moveCameraToPosition = (coords, zoom) => {
    if (mapReference) {
      mapReference.map_.panTo(coords)
      mapReference.map_.setZoom(zoom)
    }
  }

  const handleReset = () => {
    setExcludedJobsId([])
    moveCameraToPosition(defaultCenter, defaultZoom)
    setToggledProtection(undefined)
  }

  const handleFlagsToggle = (protection) => {
    if (toggledProtection && toggledProtection.id === protection.id) {
      setToggledProtection(undefined)
      moveCameraToPosition(defaultCenter, defaultZoom)
    } else {
      const middleFlag = Math.floor(protection.flags.length / 2)
      setToggledProtection(protection)
      moveCameraToPosition(
        {
          lat: protection.flags[middleFlag].established_gps_lat,
          lng: protection.flags[middleFlag].established_gps_long,
        },
        protectionZoom
      )
    }
  }

  return (
    <>
      <GoogleMapReact
        ref={(currentMapReference) => {
          mapReference = currentMapReference
        }}
        style={{ width: '100%', height: '500%' }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY,
          language: 'en',
        }}
        options={(maps) => ({
          mapTypeControl: true,
        })}>
        {jobBriefingData
          .filter(({ id }) => !excludedJobsId.includes(id))
          .map((jobBriefing, jobBriefingIndex) => (
            <JobBriefingPin
              key={`${jobBriefing.id}-${jobBriefingIndex}`}
              lat={jobBriefing.flags[0].established_gps_lat}
              lng={jobBriefing.flags[0].established_gps_long}
              jobBriefing={jobBriefing}
              onFlagsToggle={handleFlagsToggle}
              toggledProtectionId={toggledProtection && toggledProtection.id}
            />
          ))}
        {toggledProtection &&
          toggledProtection.flags.map(
            (
              {
                mile_post,
                serial_number,
                established_gps_lat,
                established_gps_long,
                is_established,
                type,
              },
              flagIndex
            ) => {
              const { isDerail, isFormB } = getFlagType({ type })
              if (isFormB || isDerail) {
                return (
                  <FlagIcon
                    key={`${mile_post}-${serial_number}-${flagIndex}`}
                    lat={established_gps_lat}
                    lng={established_gps_long}
                    style={{
                      color: getFlagIcon(type),
                      height: '50px',
                      width: '50px',
                      transform: 'translate(-26%, -50px)',
                      opacity: !is_established ? '0.5' : '1',
                    }}
                  />
                )
              }
              return (
                <LockIcon
                  key={`${mile_post}-${serial_number}-${flagIndex}`}
                  lat={established_gps_lat}
                  lng={established_gps_long}
                  style={{
                    color: colors.secondarySharpDark,
                    height: '50px',
                    width: '50px',
                    transform: 'translate(-26%, -50px)',
                    opacity: !is_established ? '0.5' : '1',
                  }}
                />
              )
            }
          )}
        {toggledProtection &&
          toggledProtection.flags.map(
            (
              {
                mile_post,
                serial_number,
                type,
                established_gps_lat,
                established_gps_long,
                is_established,
              },
              flagIndex
            ) => {
              const { isDerail, isFormB } = getFlagType({ type })
              return (
                <div
                  key={`${mile_post}-${serial_number}-${flagIndex}`}
                  lat={established_gps_lat}
                  lng={established_gps_long}
                  style={{
                    padding: '3px',
                    height: '30px',
                    width: '55px',
                    position: 'absolute',
                    textAlign: 'center',
                    left: '-15px',
                    borderRadius: '12px',
                    paddingHorizontal: '8px',
                    paddingVertical: '2px',
                    backgroundColor: colors.offwhite + 'bb',
                  }}>
                  <span
                    style={{
                      color: is_established ? colors.red : colors.text,
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}>
                    {buildFlagMarkerLabel(
                      { type, serial_number, is_established, mile_post },
                      isDerail,
                      isFormB
                    )}
                  </span>
                </div>
              )
            }
          )}
      </GoogleMapReact>
      <FilterTable
        propertiesData={propertiesData}
        excludedJobsId={excludedJobsId}
        dateFetched={dateFetched}
        onJobsFilter={handleOnJobsFilter}
        onReset={handleReset}
        onDateChange={handleOnDateChange}
      />
      <LoginOutButton msalInstance={msalInstance} />
    </>
  )
}
