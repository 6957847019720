import axios from 'axios'
import { ActionTypes } from '../constants'

export const ssoLogin = (data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.LOGIN,
    data,
  })
}

export const refreshTokens = (refreshToken) => async (dispatch) => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, { refreshToken })
  dispatch({
    type: ActionTypes.REFRESH_TOKENS,
    data,
  })
}

export const logout = () => (dispatch) =>
  dispatch({
    type: ActionTypes.RESETSTATE,
  })
