import React from 'react'
import Grid from '@material-ui/core/Grid'

const About = () => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="center"
    style={{ height: '100vh' }}>
    <Grid item>
      <span
        style={{
          fontSize: '39px',
          fontWeight: '130',
          color: 'black',
        }}>
        Apologies, looks like you found a non-existent page
      </span>
    </Grid>
  </Grid>
)

export default About
