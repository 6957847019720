import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { PersistGate } from 'redux-persist/integration/react'
import HttpsRedirect from 'react-https-redirect'

import MapPage from './containers/MapPage'
import NotFoundPage from './containers/NotFoundPage'
import SignIn from './containers/SignIn'
import theme from './themes'
import { PublicRoute, PrivateRoute } from './components'

import 'sanitize.css/sanitize.css'
import './index.css'

export default ({ store, persistor, history }) => {
  const [msalInstance, setMsalInstance] = useState(undefined)
  useEffect(() => {
    console.debug('App version 1.1.2');
  }, [])
  return (
    <HttpsRedirect>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  component={() => <MapPage msalInstance={msalInstance} />}
                />
                <PublicRoute
                  exact
                  path="/signin"
                  component={() => <SignIn setMsalInstance={setMsalInstance} />}
                  restricted
                />
                <PublicRoute component={NotFoundPage} />
              </Switch>
            </Router>
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </HttpsRedirect>
  )
}
