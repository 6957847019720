import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'

import { JobBriefingsMap } from '../../components'
import { getLocationsByDate } from '../../actions/locations'

export default ({ msalInstance }) => {
  const [fetchedDate, setFetchedDate] = useState(moment().format())

  const { locations } = useSelector((state) => ({
    locations: state.locations.data,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      initialize()
    }, 300000)
    return () => clearInterval(refreshInterval)
  }, [])

  useEffect(() => {
    initialize()
  }, [fetchedDate])

  const initialize = async () => {
    dispatch(getLocationsByDate(fetchedDate))
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh', width: '100vw' }}>
      <Grid item xs={12}>
        {!locations ? (
          <CircularProgress style={{ marginLeft: '50%' }} />
        ) : (
          <JobBriefingsMap
            jobBriefingData={locations}
            dateFetched={moment(fetchedDate)}
            handleOnDateChange={setFetchedDate}
            msalInstance={msalInstance}
          />
        )}
      </Grid>
    </Grid>
  )
}
