import { createTheme } from '@material-ui/core'

export default createTheme({
  palette: {
    primary: {
      main: '#2a4095',
    },
    secondary: {
      main: '#1C1C1C',
    },
    tonalOffset: 0.2,
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: '#1C1C1C',
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 300,
        letterSpacing: 0,
        lineHeight: '20px',
      },
    },
    slider: {
      trackColor: 'yellow',
      selectionColor: 'red',
    },
  },
})
