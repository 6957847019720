import axios from 'axios'
import storeObj from '../store'
import { logout, refreshTokens } from '../actions/auth'


const format = require('string-format')
format.extend(String.prototype, {})

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15 * 1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = storeObj.store.getState().auth.user.access_token;
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          await storeObj.store.dispatch(refreshTokens(storeObj.store.getState().auth.user.refresh_token));
          return api(originalConfig);
        } catch (_error) {
          storeObj.store.dispatch(logout());
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default api;
