import { colors } from '../utils/constants'

export const getFlagType = ({ type }) => ({
  isDerail: type === 'Derail',
  isFormB: type === 'Form B' || type === 'Form C',
  isSwitchLocks: type === 'Switch Locks',
})

export const getFlagIcon = (flag_type) => {
  switch (flag_type) {
    case 'red':
      return 'darkred'
    case 'yellow-red':
      return 'orange'
    case 'lh':
      return colors.primary
    case 'rh':
      return colors.secondarySharp
    case 'switch':
      return colors.secondarySharpDark
    default:
      return 'orange'
  }
}

export const buildFlagMarkerLabel = (flag, isDerail, isFormB) => {
  return isDerail && flag.type !== 'red'
    ? '{}: {}\n{}'.format(
        flag.type.toUpperCase(),
        flag.serial_number,
        flag.is_established ? 'OPEN' : 'CLOSED'
      )
    : isFormB || (isDerail && flag.type === 'red')
    ? 'MP {}\n{}'.format(
        flag.mile_post,
        flag.is_established ? 'OPEN' : 'CLOSED'
      )
    : '{} - ${}\n{}'.format(
        flag.mile_post,
        flag.serial_number,
        flag.is_established ? 'OPEN' : 'CLOSED'
      )
}
