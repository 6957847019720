import { initialState } from './initial'
import { ActionTypes } from '../constants/'

export default (state = initialState.locations, action) => {
  switch (action.type) {
    case ActionTypes.RESETSTATE: {
      return initialState.locations
    }
    case ActionTypes.INITIALIZE_LOCATIONS: {
      return {
        ...state,
        data: action.data,
      }
    }
    default: {
      return state
    }
  }
}
