import React from 'react'
import Switch from '@material-ui/core/Switch'
import RoomIcon from '@material-ui/icons/Room'
import PersonIcon from '@material-ui/icons/Person'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core/styles'

import { getPropertyColor } from '../utils/hofs'
import useVisible from '../utils/useVisible'
import { getHasAlreadyReportedToDuty } from '../utils/users'

const useStyles = makeStyles({
  switchBase: {
    '&$checked': {
      color: '#9AD465',
    },
    '&$checked + $track': {
      backgroundColor: '#D8D8D8',
    },
  },
  checked: {},
  track: {},
})

export default ({ jobBriefing, onFlagsToggle, toggledProtectionId }) => {
  const classes = useStyles()
  const { ref, isVisible, setIsVisible } = useVisible(false)

  const protectionIsToggled = toggledProtectionId === jobBriefing.id

  const getFontSize = (str) =>
    str.lenght >= 16 ? '11px' : str.lenght >= 14 ? '13px' : '15px'

  const isOnDuty = getHasAlreadyReportedToDuty(jobBriefing)

  return (
    <>
      <RoomIcon
        style={{
          color: getPropertyColor(jobBriefing.org_name),
          position: 'absolute',
          transform: !protectionIsToggled && 'translate(-50%, -50px)',
          height: '50px',
          width: '50px',
          cursor: 'pointer',
        }}
        onClick={() => setIsVisible(!isVisible)}
      />
      {isVisible && (
        <div
          ref={ref}
          style={{
            position: 'absolute',
            width: '271px',
            height: '110px',
            padding: '20px 21px',
            backgroundColor: 'white',
            textAlign: 'left',
            fontSize: '14px',
            transform: protectionIsToggled
              ? 'translate(22%, -38%)'
              : 'translate(15%, -65%)',
            borderRadius: '8px',
            zIndex: 10,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 7.5,
                borderRight: '0.5px solid rgb(151, 151, 151, 0.45)',
              }}>
              <div>
                <PersonIcon
                  style={{
                    color: '#2C3B8B',
                    verticalAlign: 'bottom',
                    fontSize: '20px',
                  }}
                />
                <span
                  style={{
                    color: '#2C3B8B',
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                    fontWeight: 'bold',
                    letterSpacing: '0',
                    lineHeight: '10px',
                  }}>
                  {jobBriefing.created_by_user_name}
                </span>
                {isOnDuty && (
                  <FiberManualRecordIcon
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      marginLeft: '2px',
                    }}
                  />
                )}
              </div>
              <br />
              <span
                style={{
                  color: '#1C1C1C',
                  fontFamily: 'Roboto',
                  fontSize: '15px',
                  fontWeight: '300',
                  letterSpacing: '0',
                  lineHeight: '10px',
                }}>
                {jobBriefing.org_name}
              </span>
              <br />
              <span
                style={{
                  color: '#1C1C1C',
                  fontFamily: 'Roboto',
                  fontSize: getFontSize(jobBriefing.subdivision),
                  fontWeight: '300',
                  letterSpacing: '0',
                  lineHeight: '10px',
                }}>
                {jobBriefing.subdivision} Sub
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 4.5,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <span
                style={{
                  color: '#1C1C1C',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '300',
                  letterSpacing: '0',
                  lineHeight: '10px',
                }}>
                Show Flags
              </span>
              <Switch
                checked={protectionIsToggled}
                onClick={() => {
                  if (protectionIsToggled) setIsVisible(false)
                  onFlagsToggle(jobBriefing)
                }}
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
