import { createLogger } from 'redux-logger'

const actions = []
const showAll = true

const includeAlways = (actionType) => actions.includes(actionType) || showAll
const includeTemporarily = (actionType) =>
  window &&
  window.MY_GLOBAL &&
  window.MY_GLOBAL.log &&
  window.MY_GLOBAL.log.enabled === true &&
  window.MY_GLOBAL.log.actions &&
  window.MY_GLOBAL.log.actions.includes(actionType)

export default createLogger({
  level: 'info',
  collapsed: true,
  predicate: (getState, action) =>
    includeAlways(action.type) || includeTemporarily(action.type),
})
