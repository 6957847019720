import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

export default ({ component: Component, restricted, ...rest }) => {
  const { userData } = useSelector((state) => ({
    userData: state.auth.user,
  }))
  return !userData ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect to="/" />
  )
}
