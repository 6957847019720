import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import ExitIcon from '@material-ui/icons/ExitToApp'
import LoginIcon from '@material-ui/icons/VerifiedUser'
import { logout, ssoLogin } from '../actions/auth'

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export default ({ isLogin }) => {
  const dispatch = useDispatch()
  const handleLogin = async () => {
    const popup = window.open(`${process.env.REACT_APP_API_URL}/auth/azure`);
    window.addEventListener('message', ({ data }) => {
      if (data && data.type === 'login') {
        const user = parseJwt(data.data.access_token);
        dispatch(ssoLogin({
          ...user,
          access_token: data.data.access_token,
          refresh_token: data.data.refresh_token,
        }));
        popup.close();
      }
    }, false);
  };

  return isLogin ? (
    <Button
      variant="contained"
      color="primary"
      onClick={handleLogin}>
      Login <LoginIcon />
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      style={{
        position: 'absolute',
        right: '58px',
        top: '10px',
        height: '40px',
        minWidth: '40px',
        padding: '0px',
      }}
      onClick={() => dispatch(logout())}>
      <ExitIcon />
    </Button>
  )
}
