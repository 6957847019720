import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

export default ({ component: Component, ...rest }) => {
  const { userData } = useSelector((state) => ({
    userData: state.auth.user,
  }))

  return (
    <Route
      {...rest}
      render={(props) =>
        userData ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  )
}
