import { initialState } from './initial'
import { ActionTypes } from '../constants/'

export default (state = initialState.auth, action) => {
  switch (action.type) {
    case ActionTypes.RESETSTATE: {
      return initialState.auth
    }
    case ActionTypes.LOGIN: {
      return {
        ...state,
        user: action.data,
      }
    }
    case ActionTypes.REFRESH_TOKENS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        }
      };
    default: {
      return state
    }
  }
}
