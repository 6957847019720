import React from 'react'
import ReactDOM from 'react-dom'
import history from './history'
import storeConfig from './store'

import App from './app'

ReactDOM.render(
  <App
    store={storeConfig.store}
    persistor={storeConfig.persistor}
    history={history}
  />,
  document.querySelector('#root')
)

if (module.hot) {
  module.hot.accept('./app', () => {
    ReactDOM.render(
      <App
        store={storeConfig.store}
        persistor={storeConfig.persistor}
        history={history}
      />,
      document.querySelector('#root')
    )
  })
}
