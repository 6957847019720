import { createStore, applyMiddleware } from 'redux'
import ReduxPromise from 'redux-promise'
import ReduxThunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from './reducers'
import Logger from './logger'

const persistConfig = {
  key: 'auth',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const middlewares =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(promise, ReduxPromise, ReduxThunk)
    : applyMiddleware(promise, ReduxPromise, ReduxThunk, Logger)

export const configureStore = (initialState) => {
  const store = createStore(persistedReducer, initialState, middlewares)
  const persistor = persistStore(store)
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return { store, persistor }
}

const store = configureStore()
export default store
