const stringToColour = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

const propertiesColorCode = {
  'UP North': '#ffcc00',
  'UP South': '#ffcc00',
  'UP West': '#ffcc00',
  BNSF: '#f36010',
  KCS: '#da413d',
  NS: '#034764',
  FEC: '#00468b',
  CP: '#c3002f',
  CN: '#f2555b',
  SCRRA: '#3a8285',
  CSX: '#305671',
}

export const getPropertyColor = (propertyName) =>
  propertiesColorCode[propertyName] || stringToColour(propertyName)
