// import { customGet, customPost } from '../services/requests'

const propertiesData = [
  { id: 1, name: 'CP' },
  { id: 2, name: 'BNSF' },
  { id: 6, name: 'UP West' },
  { id: 7, name: 'SCRRA' },
  { id: 8, name: 'UP North' },
  { id: 9, name: 'UP South' },
  { id: 10, name: 'FEC' },
  { id: 11, name: 'CN' },
  { id: 12, name: 'KCS' },
  { id: 13, name: 'Sunrail' },
  { id: 14, name: 'SFRTA' },
  { id: 22, name: 'NS' },
  { id: 32, name: 'AMTRAK Northeast' },
  { id: 42, name: 'CSX' },
  { id: 52, name: 'TRRA' },
  { id: 62, name: 'AMTRAK Midwest' },
]

export const getAllPropertiesData = () => propertiesData
